<template>
  <!-- 操作日志 -->
  <div>
    <!-- 顶部输入框 -->
    <div class="search">
      <div>
        <p>按手机号查询</p>
        <el-input size="" v-model="phone" placeholder="手机号码" style="width: 200px"></el-input>
      </div>
      <div>
        <p>按姓名查询</p>
        <el-input size="" v-model="userId" placeholder="用户id" style="width: 200px; margin: 0 20px"></el-input>
      </div>
      <div style="margin-top: 53px; height: 50px">
        <el-button type="primary" size="">查询</el-button>
        <el-button type="primary" size="" @click="reset">重置</el-button>
      </div>
    </div>
    <!-- 中间表格区 -->
    <div>
      <el-table :data="tableData" border>
        <el-table-column label="序号" prop="id"></el-table-column>
        <el-table-column label="手机号" prop="buyTel"></el-table-column>
        <el-table-column label="姓名" prop="buyName"></el-table-column>
        <el-table-column label="数字通证（总量/冻结）" prop="shuZi"></el-table-column>
        <el-table-column label="CNY（总量/冻结）" prop="Cny"></el-table-column>
        <el-table-column label="操作" prop="Caozuo">
          <template slot-scope="scope">
            <a href="">{{ scope.row.tag }}详情</a>
            <!-- <el-tag
          :type="scope.row.tag === '家' ? 'primary' : 'success'"
          disable-transitions>{{scope.row.tag}}</el-tag> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: '',
      userId: '',
      total: 23,
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      tableData: []
    }
  },
  created() {
    this.onNesClassification()
  },
  methods: {
    //   重置
    reset() {
      this.phone = ''
      this.userId = ''
    },
    // 获取详情
    onNesClassification() {
      let newid = this.$route.query.id
      this.axios
        .get('/admin/turnover/adminFindAll', {
          id: newid
        })
        .then(res => {
          this.tableData = res.data.data.page.records
          console.log(this.tableData)
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  padding: 10px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
