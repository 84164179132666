import { render, staticRenderFns } from "./addknockDown.vue?vue&type=template&id=ac6d6566&scoped=true&"
import script from "./addknockDown.vue?vue&type=script&lang=js&"
export * from "./addknockDown.vue?vue&type=script&lang=js&"
import style0 from "./addknockDown.vue?vue&type=style&index=0&id=ac6d6566&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac6d6566",
  null
  
)

export default component.exports